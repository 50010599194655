@font-face {
    font-family: "Logo";
    src: local("logofont"),
        url("../assets/logofont.ttf") format("truetype");
}
.nameandemail:hover {
cursor: default;

}

#namedropdown {
    padding-top: 5px;
}

#usernamedropdow {
    font-size: 1.51vw;
}

#therest> div:hover{
    color: black;
    cursor: pointer;
}

#therest> div {
    padding-top: 5px;
    border-top: 0.5px solid black;
}

.hidden {
    display: none;
}

.profile-dropdown {
    position: absolute;
    background-color: lightgrey;
    color: white;
    border-radius: 5px;
    padding: .51vw;
}

.homebutton  {
    cursor: pointer;
}
.navcont {
    color: white;
    height: 100%;
    font-size: 2vw;
    font-family: Logo;
}

/* #usernamedropdow { */
    /* border-bottom: 1px solid red; */
/* } */

#navcontainer {
    position: fixed;
    background-color: #ffc000;
    /* border: 1px solid red; */
    width: 100vw;
    display: flex;
    justify-content: space-around;
    height: 5vw;
    z-index: 2;
}

.navleft {
    margin-top: 15px;
}

.navright {
    margin-top: 15px;
}
