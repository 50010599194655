.splash-page-container {
    display: flex;
    flex-direction:column;
    background-color: black;
    height: 1200px;
    width: 100vw;
}

.home-background-image {
    position: absolute;
    height: 100vh;
    width: 100vw;
    opacity: 0.5;
    z-index: 1;
}

.logo-phone-img-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 5em;
    z-index: 2;
    align-items: flex-end;
}

.text-log-subcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-img {
    z-index: 2;
    margin: 1em;
}

.phone-img {
    position: relative;
    top: 140px;
    z-index: 2;
}

.splashpage-text {
    font-size: 45px;
    color: white;
}

.splash-navigate-all-beers {
    display: inline-block;
    border-radius: 4px;
    background-color: #ffc000;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    width: 80%;
    padding: 0.5em;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
    z-index: 2;
}

.splash-navigate-all-beers span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

.splash-navigate-all-beers span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.splash-navigate-all-beers:hover span {
    padding-right: 25px;
}

.splash-navigate-all-beers:hover span:after {
    opacity: 1;
    right: 0;
}
