.brewery-stats{
    width:25%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    margin-left: 30%;
    padding:20px;
}

.beer-feed{
    width:60%;
    margin-right: 20%;
    margin-left: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.brew-info{
    display: flex;
    max-width: 1048px;
    min-width: 320px;
    width: 100%;
 }

 .beerdata{
    width:33.33%;
    border-bottom: 0.5px rgba(214, 205, 205, 0.5) solid;
    height: 50px;
    font-size: 20px;;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1048px;
    min-width: 320px;
    margin-bottom: 0.5em;
    margin-top:0.5em;
    border: 0.5px solid rgba(128, 128, 128, 0.5);
    border-radius: 4px;
}

.brewery-header{
    /* display: flex;
    width: 100%;
    justify-content: center; */
   display:flex;

   max-width: 1048px;
   min-width: 320px;
   width: 100%;


   box-shadow: 0px 0px 1px gray;
}

.brew-logo{
    width: 150px;
    height: auto;
    padding: 20px;
}
