.beer-icon{
    cursor: pointer;
}
.beers-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 10%;
    margin-right: 10%;

}

.beer-card{
    width: 50%;
    border-bottom: 1px solid #e9e9e9;
    background-color: rgba(255, 191, 0, 0.1);
    margin-bottom: 1em;
    box-shadow: 0px 0px 5px lightgrey;
    border-radius: 5px;
}

.home-title{
    margin-left: 20px;
}
