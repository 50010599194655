/* TODO Add site wide styles */
body {
    margin: 0px;
}

#app-js {
    width: 100vw
}

#all-content {
    display: flex;
    flex-direction:column;
    align-items: center;
    padding-top: 5vw;
    background-color: white;
}

#usernamedropdown {
    border-bottom: 1px solid rgb(102, 64, 64);
}

.rating-review {
    width: 15vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
