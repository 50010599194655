.beer-feed{
    width:60%;
    margin-right: 20%;
    margin-left: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 5px lightgrey;
}
.beer-reviews{
   display:grid;
   grid-template-columns: repeat(2, 1fr);
   max-width: 1048px;
   min-width: 320px;
   width: 100%;
   margin-top: 0.5em;
   border-radius: 5px;
   border-radius: 4px;
   box-shadow: 0px 0px 5px lightgrey;
}
.card-container{
    display: flex;
    justify-content: center;
    width: 100%;
}

.onebeer-header{
    /* display: flex;
    width: 100%;
    justify-content: center; */
   display:flex;
   max-width: 1048px;
   min-width: 320px;
   width: 100%;
   padding-bottom: 5px;

}

#beer-top{
    box-shadow: 0px 0px 4px lightgrey;
}

.beer-info{
   display: flex;
   max-width: 1048px;
   min-width: 320px;
   width: 100%;
   color: #595959;
   border-left: 0.5px rgba(214, 205, 205, 0.5) solid;
   border-right: 0.5px rgba(214, 205, 205, 0.5) solid;;
}

.beerdata{
    width:25%;
    border-bottom: 0.5px rgba(214, 205, 205, 0.5) solid;
    height: 50px;
    font-size: 20px;;
    display: flex;
    justify-content: center;
    align-items: center;
}

.beer-logo{
    width: 150px;
    height: auto;
    padding: 20px;
}

.left{

    /* border-left: 0.5px rgba(214, 205, 205, 0.5) solid; */
}

.right{
    border-left: 0.5px rgba(214, 205, 205, 0.5) solid;
    /* border-right: 0.5px rgba(214, 205, 205, 0.5) solid; */
}

.mid{

    border-left: 0.5px rgba(214, 205, 205, 0.5) solid;
}

.stat-container{
    width:35%;
    min-height: 100%;
    margin-left: auto;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.review-stats{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    font-family: Helvetica, sans-serif;
    color: #595959;
    font-size: 20px;
}

.stat-title{
    text-align: center;
    margin-bottom: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    color: #595959;
}

.bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1048px;
    min-width: 320px;
    height: 100px;
    border: 0.5px rgba(214, 205, 205, 0.5) solid;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.description{
   width: 75%;
   padding:18px;
   font-size: 20px;
   font-family: Helvetica, sans-serif;
   color: #595959;
   overflow: hidden;
}
.checkin{
    width:10%;
    background-color: green;
    display: flex;
    justify-content: center;
    align-items:center;

}

.check-in{
    background-color: green;
}

.checkin:hover{
    cursor: pointer;
}
.g{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.g1{
    border-bottom: 0.5px rgba(214, 205, 205, 0.5) solid;
    border-top: 0.5px rgba(214, 205, 205, 0.5) solid;
}

.g2{
    border-right: 0.5px rgba(214, 205, 205, 0.5) solid;
}

#brewLink {
    color: blue;
    text-decoration: underline;
}
#brewLink:hover{
    cursor: pointer;
    color: rgb(2, 2, 182);
}

.check{
   padding-left: 30px;
}

.review-stats > div {
    padding:5px;
}

.cc > .check-in{
    background-color: green;
    background-image: url("checkicon.png");
    background-repeat: no-repeat;
    height: 60px;
    width: 60px;
    background-size:contain;
    background-position: center;
    padding:0px;
    border: none;
    border-radius: 3px;
}

.cc > .check-in:hover{
    box-shadow: 0px 0px 7px #595959;

}

.cc {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#checkin-title{
    text-align: center;
}
