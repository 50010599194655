










.badge-description {
    font-family: Logo;
    display: flex;
    align-items: center;
    vertical-align: middle;
    padding-left: 2vw;
}


.badge-icon{
    height: 10vw;
    width: 10vw;
    box-sizing: border-box;
    object-fit: fill;
}


.mybadge-badge{

display: flex;
flex-direction: row;
width:25%;
}

.badge-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
