






#modal-form>select {
  width: 10vw;
  background-color: rgb(0, 0, 0);
  color: white;
  padding: .51vw;
  margin-bottom: 1vw;
}


#delete-button {
  width: 7vw;
  background-color: rgb(182, 51, 51);
  color: rgb(0, 0, 0);
  padding: .31vw;
  margin-bottom: 1vw;
  font-family: 'Logo';
  margin-top: 1vw;
  cursor: pointer;

}
#modal-form>input {
  width: 10vw;
  background-color: rgb(0, 0, 0);
  color: white;
  padding: .51vw;
  margin-bottom: 1vw;
}

#rating-box{
  width: 7vw;
  display: flex;
  justify-content: space-between;
}

#modal-submit {
  width: 7vw;
  font-family: 'Logo';
  padding: .25vw;
  color: white;
  background-color: #1b8811;
  cursor: pointer;
  margin-bottom: 1vw;
}
































.modal-title {
  font-size: 2vw;
}
.error-cont {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 1vw;
  color: rgb(255, 74, 74)
}
.error-message{
  font-size: .5vw;
  color: rgb(255, 74, 74)
}

.div-button {
  border: 1px black solid;
  padding: .5vw;
  cursor: pointer;
  color: white;
  background-color: black;
}

.modal-exit {
  cursor: pointer;
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 30vw;
  min-height: 3vw;
  padding-right: 5vw;
  padding: .51vw;
  padding-bottom: none;
  align-items: flex-start;
  border-bottom: 1px solid;
  background-color: black;
  color: white;
}

.modal-form {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.modal-whole {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: Logo;
  min-width: 30vw;
}

#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color: white;
}
